<template>
  <presentation-message :list="list" @done="$emit('done')"></presentation-message>
</template>

<script>
  import PresentationMessage from "../../component/PresentationMessage";
  export default {
    name: "SuperBuilderStep1",
    components: {
      PresentationMessage
    },
    data() {
      return {
        list: [
          '안녕하세요',
          '서비스를 준비하고 계시는군요!',
          '런치팩이 도와드릴께요 :)'
        ]
      }
    }
  }
</script>
